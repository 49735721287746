<template>
  <div class="col-12 c">
    <div class="card">
      <div class="card-header">
        <h4 class="text-center">
          <i class="fa fa-info"></i>
          تنبيهات مشرفين الباصات
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>الى</th>
              <th>المشرف</th>
              <th>الباص</th>
              <th>التاريخ</th>
              <th>التنبيه</th>
              <th>تمت رؤيته</th>
            </thead>
            <tbody>
              <tr
                v-for="log in logs"
                :key="log._id"
                :style="
                  !log.seen
                    ? 'background:lightyellow; border-right: 2px solid red'
                    : ''
                "
              >
                <td>
                  <span v-if="log.to == 'admin'">للادارة</span>
                  <span v-if="log.to == 'parents'">لاولياء الامور</span>
                </td>
                <td>{{ log.admin_name }}</td>
                <td>{{ log.bus_name }}</td>
                <td>{{ log.date }}</td>
                <td>{{ log.content }}</td>
                <td>
                  <button
                    class="btn btn-secondary"
                    @click="seen(log._id)"
                    v-if="!log.seen"
                  >
                    <i class="fa fa-eye"></i>
                    تمت رؤيته
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    if (!checkPer("alerts")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    g.get();
  },
  data() {
    return {
      logs: [],
      user: JSON.parse(localStorage.getItem("user")),
      date: null,
    };
  },
  methods: {
    get() {
      var g = this;
      $.post(api + "/user/general/alerts", {
        jwt: g.user.jwt,
      })
        .then(function (r) {
          g.logs = r;
        })
        .catch(function (r) {
          alert("حدث خطأ");
        });
    },
    seen(id) {
      var g = this;
      $.post(api + "/user/general/alerts-seen", {
        jwt: g.user.jwt,
        id: id,
      })
        .then(function (r) {
          g.get();
        })
        .catch(function (r) {
          alert("حدث خطأ");
        });
    },
  },
};
</script>

<style>
</style>